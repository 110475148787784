.plan {

    &__section {
        background-color: rgb(246, 249, 252);
        font-family: Arial;
        padding-top: 114px;
        padding-bottom: 326px;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 241px), 0% 100%);

        @media (max-width: 1084px) {
            padding-top: 70px;
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - calc(79px + (241 - 79) * ((100vw - 320px) / (1084 - 320)))), 0% 100%);
            padding-bottom: calc(94px + (326 - 94) * ((100vw - 320px) / (1084 - 320)));
        }

        @media (max-width: 767px) {
            padding-top: 28px;
        }
    }

    &__title-block {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 123px;

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    &__title {
        max-width: 598px;
        width: 100%;

        color: rgb(0, 0, 0);
        font-size: 56px;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: left;

        @media (max-width: 1084px) {
            font-size: calc(28px + (56 - 28) * ((100vw - 320px) / (1084 - 320)));
            line-height: calc(36px + (64 - 36) * ((100vw - 320px) / (1084 - 320)));
        }

    }

    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        align-items: flex-end;

        @media (max-width:767px) {
            grid-template-columns: 1fr;
        }

        &-block {
            position: relative;
            padding: 37px 45px;
            background: rgb(255, 255, 255);
            min-height: 612px;

            @media (max-width: 1023px) {
                padding: 26px 30px;
            }

            @media (max-width: 767px) {
                min-height: 362px;
                padding: 19px 21px 26px;
            }
        }

        &-label {
            position: absolute;
            width: 188px;
            right: -20px;
            top: 24px;
            line-height: 24px;
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            text-align: center;
            padding: 11px;
            background-color: #2196F3;
            border-radius: 8px 8px 0px 8px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                width: 20px;
                height: 33px;
                right: 0;
                background-color: #007EE2;
                clip-path: polygon(0 0, 0% 100%, 100% 0);
            }
        }
    }

    &__info-basic {
        border: 0.2px solid rgba(79, 91, 118, 0.5);
        border-radius: 8px;

        .plan__info-item-icon {
            background-color: #004b89;
        }

        @media (max-width:767px) {
            grid-template-columns: 1fr;
        }
    }

    &__info-title {
        margin-bottom: 66px;

        @media (max-width:767px) {
            margin-bottom: 15px;
        }

        h3 {
            color: rgb(0, 0, 0);
            font-size: 56px;
            font-weight: 400;
            line-height: 64px;
            letter-spacing: 0px;
            text-align: left;

            @media (max-width: 1084px) {
                font-size: calc(28px + (56 - 28) * ((100vw - 320px) / (1084 - 320)));
                line-height: calc(32px + (64 - 32) * ((100vw - 320px) / (1084 - 320)));
            }
        }

        &-standart {
            h3 {
                color: #1fb1ff;
            }
        }
    }

    &__info-list {
        display: flex;
        flex-direction: column;
        gap: 47px;
        position: relative;

        @media (max-width:767px) {
            gap: 33px;
        }
    }

    &__info-item {
        padding-left: 100px;
        color: rgb(0, 0, 0);
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        position: relative;

        @media (max-width:1023px) {
            padding-left: 50px;
        }

        @media (max-width:767px) {
            padding-left: 32px;
        }
    }

    &__info-item-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1FB1FF;
        border-radius: 50%;
        padding: 7px;

        @media (max-width:1023px) {
            width: 24px;
            height: 24px;
            padding: 6px;
            top: 5px;
        }

        @media (max-width:767px) {
            width: 12px;
            height: 12px;
            padding: 3px;
        }

        img {
            width: 100%;
        }
    }

    &__info-item-text {
        text-align: left;
        color: rgb(0, 0, 0);
        font-family: Arial;
        font-size: 28px;
        font-weight: 400;
        line-height: 34px;
        text-align: left;

        @media (max-width: 1084px) {
            font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1084 - 320)));
            line-height: calc(24px + (34 - 24) * ((100vw - 320px) / (1084 - 320)));
        }

        &::before {
            content: '';

        }

        &-bold {
            font-weight: bold;
        }
    }

    &__info-standart {
        border-radius: 0 76px 0 0;
        border: 0.2px solid #4F5B76;
        box-shadow: 0px 4px 18px #2196F3;
        border-radius: 8px;
        height: 100%;
        padding-top: 80px;
        min-height: 655px;

        @media (max-width: 767px) {
            min-height: 362px;
        }
    }
}