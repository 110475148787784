.partnership {
    &__section {
        position: relative;
        overflow: hidden;
        padding-top: 115px;
        padding-bottom: 434px;
        background-color: #064986;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 400px));

        @media (max-width: 1084px) {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - calc(140px + (400 - 140) * ((100vw - 320px) / (1084 - 320)))));
            padding-bottom: calc(160px + (434 - 160) * ((100vw - 320px) / (1084 - 320)));
        }

        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }

    &__title-block {
        padding-bottom: 33px;
    }

    &__title {
        color: rgb(255, 255, 255);
        font-family: Arial;
        font-size: 56px;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: left;
        text-align: center;

        @media (max-width: 1084px) {
            font-size: calc(34px + (56 - 34) * ((100vw - 320px) / (1084 - 320)));
            line-height: calc(39px + (64 - 39) * ((100vw - 320px) / (1084 - 320)));
        }
    }

    &__info {
        position: relative;
        padding-top: 99px;
        display: flex;
        justify-content: flex-end;

        @media (max-width:767px) {
            padding-top: 0px;
            margin-top: 50px;
        }

        &-block {
            position: relative;
            max-width: 96.5%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 66px;
            overflow: hidden;
            margin-left: 3.5%;
            margin-bottom: 64px;

            @media (max-width:767px) {
                max-width: 100%;
            }
        }
    }

    &__circle {
        position: absolute;
        top: 0;
        left: 3.5%;
        width: 71px;
        height: 71px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 50%;
        transform: translateX(-50%);
        animation: hiddenBorder 3000ms infinite;

        @media (max-width: 1084px) {
            width: calc(36px + (71 - 36) * ((100vw - 320px) / (1084 - 320)));
            height: calc(36px + (71 - 36) * ((100vw - 320px) / (1084 - 320)));
        }

        @media (max-width:767px) {
            top: unset;
            bottom: 100%;
        }
    }

    &__cirle-inter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        border-radius: 50%;
        border: 1px solid #fff;
        width: 44px;
        height: 44px;

        animation: hiddenBorder 3000ms infinite;
        animation-delay: 0.75s;

        @media (max-width: 1084px) {
            width: calc(21px + (44 - 21) * ((100vw - 320px) / (1084 - 320)));
            height: calc(21px + (44 - 21) * ((100vw - 320px) / (1084 - 320)));
        }

        &::before {
            content: '';
            display: block;
            background: rgb(0, 216, 255);
            border-radius: 50%;
            width: 100%;
            height: 100%;
            animation: hiddenBoll 3000ms infinite;
            animation-delay: 1.5s;
        }
    }

    &__line-big-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: calc(100% - 42px);
        background-color: #fff;

        @media (max-width: 768px) {
            height: calc(100% - 31px);
        }

        @media (max-width: 707px) {
            height: calc(100% - 42px);
        }

        @media (max-width: 384px) {
            height: calc(100% - 50px);
        }
    }

    &__item {
        position: relative;
        border: 1px solid rgb(66, 84, 102);
        border-radius: 8px;
        box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.2);
        padding: 26px 30px;
        color: rgb(255, 255, 255);
        font-family: Arial;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: center;

        @media (max-width: 1084px) {
            font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1084 - 320)));
            line-height: calc(21px + (28 - 21) * ((100vw - 320px) / (1084 - 320)));
        }

        @media (max-width: 768px) {
            max-width: 100%;
            padding: 18px 15px;
        }

        &-wrapper {
            padding-right: 42px;
            max-width: 48%;
            width: 100%;
            padding-left: 16%;
            overflow: hidden;

            &-big {
                padding-left: 16%;
            }

            @media (max-width: 768px) {
                max-width: 100%;
                padding-right: 0px;
            }

        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: #fff;
            height: 1px;
            width: 100%;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
        }

        &-first {
            @media (min-width: 769px) {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: #fff;
                    height: 1px;
                    width: 100%;
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &-group {
            display: flex;
            align-items: center;
            width: 100%;
            overflow: hidden;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &-block {
            padding-left: 41px;
            display: flex;
            flex-direction: column;
            gap: 28px;
            max-width: 52%;
            width: 100%;
            overflow: hidden;

            @media (max-width: 768px) {
                max-width: 82%;
                padding-top: 13px;
                margin-left: 18%;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: #fff;
                    height: calc(50% + 15px);
                    width: 1px;
                    left: 0px;
                    top: 0;
                }
            }
        }

        &-section {
            &::after {
                content: '';
                display: block;
                position: absolute;
                background-color: #fff;
                height: calc(50% + 15px);
                width: 1px;
                left: -42px;
                top: 50%;
            }

            &-second {
                &::after {
                    top: unset;
                    bottom: 50%;
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 30px;

        &-item {
            color: rgb(173, 189, 204);
            font-family: Arial;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
        }
    }
}

@keyframes glowing {
    0% {
        background-color: rgb(0, 216, 255);
        width: 100%;
        height: 100%;
    }

    50% {
        background-color: #fff;
        transform: scale(0.7);
    }

    100% {
        background-color: rgb(0, 216, 255);
        width: 100%;
        height: 100%;
    }
}

@keyframes hiddenBorder {
    0% {
        border: 1px solid #fff;
    }

    50% {
        border: 1px solid rgba(0, 0, 0, 0);
    }

    100% {
        border: 1px solid #fff;
    }
}

@keyframes hiddenBoll {
    0% {
        background-color: rgb(0, 216, 255);
        transform: scale(0.9);
    }

    50% {
        background-color: rgba(0, 0, 0, 0);
        transform: scale(0.9);
    }

    100% {
        background-color: rgb(0, 216, 255);
        transform: scale(0.9);
    }
}