.footer {
    &__section {
        padding-top: 70px;
        padding-bottom: 46px;
        background-color: #064986;

        @media (max-width:767px) {
            padding-top: 48px;
            padding-bottom: 62px;
        }
    }

    &__block {
        display: grid;
        grid-template-columns: 451px 1fr;
        gap: 63px;

        @media (max-width: 1023px) {
            grid-template-columns: 1fr;
        }
    }

    &__info {
        font-family: Arial;
    }

    &__info-title {
        h2 {
            color: rgb(255, 255, 255);
            font-size: 56px;
            font-weight: 400;
            line-height: 56px;
            letter-spacing: 0.2%;
            text-align: left;
            margin-bottom: 55px;

            @media (max-width: 1084px) {
                font-size: calc(38px + (56 - 38) * ((100vw - 320px) / (1084 - 320)));
                line-height: calc(44px + (56 - 44) * ((100vw - 320px) / (1084 - 320)));
            }

            @media (max-width:767px) {
                margin-bottom: 16px;
            }
        }
    }

    &__info-text {
        color: rgb(173, 189, 204);
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;

        @media (max-width: 767px) {
            line-height: 24px;
        }
    }

    &__info-contact-title {
        margin-top: 53px;
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.2%;
        text-align: left;

        @media (max-width: 767px) {
            margin-top: 27px;
        }
    }

    &__info-contact-block {
        margin-top: 35px;
        color: rgb(173, 189, 204);
        font-family: Arial;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;

        a {
            color: rgb(173, 189, 204);
        }

        @media (max-width: 767px) {
            margin-top: 27px;
        }
    }
}

.contact {
    &__form {
        border: 1px solid rgb(71, 98, 133);
        border-radius: 8px;
        background: rgb(255, 255, 255);
        padding: 20px 20px 33px 40px;
        overflow: hidden;

        @media (max-width: 767px) {
            padding: 20px 20px 33px 20px;
        }

        &-thanks {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            user-select: none;
            transition: .3s;
        }

        &.thanks {
            position: relative;

            .contact__form-thanks {
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                transition: .3s;
                z-index: 9;
            }
        }

        &-input-block {
            display: grid;
            grid-template-columns: 120px 1fr;
            gap: 40px;
            align-items: center;
            margin-bottom: 15px;

            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                gap: 8px;
            }
        }

        &-input-title {
            h3 {
                color: rgb(0, 0, 0);
                font-family: Arial;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
            }
        }

        &-input {
            border-radius: 8px;
            background: rgb(246, 249, 252);
            color: #000;
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            height: 32px;
            padding: 7px 12px 9px;
            width: 100%;

            &-wrapper {
                width: 100%;
                position: relative;

                svg {
                    display: none;
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    width: 24px;
                    height: 24px;
                }

                &.error {
                    .contact__form-input {
                        padding-right: 32px;
                    }

                    svg {
                        display: block;
                    }
                }
            }


            &::placeholder {
                color: rgb(108, 109, 109);
                font-family: Arial;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
            }
        }

        &-checkbox-block {
            padding-left: 159px;
            display: flex;
            align-items: flex-start;

            @media (max-width: 767px) {
                padding-left: 0;
            }
        }

        &-checkbox-label {
            display: flex;
            align-items: flex-start;
            gap: 13px;
            color: rgb(0, 0, 0);
            font-family: Arial;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
        }

        &-submit-block {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 31px;
        }

        &-textarea-block {
            align-items: flex-start;
        }

        &-textarea {
            resize: none;
            height: 98px;
        }

        &-recaptcha {
            display: flex;
            justify-content: center;
        }

        &-submit {
            border-radius: 16.5px;
            background: rgb(6, 73, 134);
            max-width: 257px;
            margin-left: -20px;
            width: 100%;
            padding: 9px;
            color: rgb(255, 255, 255);
            font-family: Arial;
            font-size: 14.77px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
            text-align: center;

            cursor: pointer;
        }
    }
}