.culture {
    &__section {
        padding-top: 102px;
        padding-bottom: 172px;

        @media (max-width:767px) {
            padding-top: 54px;
            padding-bottom: 62px;
        }
    }


    &__container {}

    &__title-block {
        margin-bottom: 50px;
    }

    &__title {
        color: rgb(0, 0, 0);
        font-family: Arial;
        font-size: 56px;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: center;

        @media (max-width: 1084px) {
            font-size: calc(34px + (56 - 34) * ((100vw - 320px) / (1084 - 320)));
        }
    }

    &__info {
        &-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                gap: 10px;
            }

            @media (max-width:992px) and (min-width:768px) {
                gap: 8px;
            }
        }

        &-contacts {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                gap: 10px;
            }

            @media (max-width:992px) and (min-width:768px) {
                gap: 8px;
            }
        }
    }

    &__info-item {
        min-height: 230px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        padding: 19px 19px 19px;
        border: 0.2px solid rgb(79, 91, 118);
        border-radius: 8px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        background: rgb(255, 255, 255);
        text-align: center;
        width: 100%;

        h3 {
            color: rgb(0, 0, 0);
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            text-align: center;
        }

        @media (max-width:992px) and (min-width:768px) {
            padding: 19px 10px 49px;
        }
    }

    &__info-item-icon {
        min-height: 38px;
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
