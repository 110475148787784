.main {
    &__section {
        position: relative;
        height: 100%;
        min-height: 914px;
        overflow: hidden;

        @media (max-width:1920px) {
            min-height: calc(365px + (914 - 365) * ((100vw - 320px) / (1920 - 320)));
        }

        @media (max-width: 768px) {
            display: flex;
            align-items: center;
        }
    }

    &__contact-block {
        position: absolute;
        top: 42px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        z-index: 3;
    }

    &__contact-container {
        max-width: 1082px;
        padding: 0 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__contact {
        border-radius: 9px;
        backdrop-filter: blur(4px);
        background: rgba(255, 255, 255, 0.4);
        color: rgb(255, 255, 255);
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0%;
        text-align: left;
        padding: 7px 43px;
    }

    &__logo-block {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        mix-blend-mode: color-dodge;
        display: block;

        @media (max-width: 768px) {
            padding-bottom: 50px;
        }

        @media (max-width: 480px) {
            svg {
                min-width: calc(100% + 200px);
                right: 50%;
                position: absolute;
                transform: translate(50%, -50%);
                top: 50%;
            }
        }
    }

    &__logo-block-contact {
        position: relative;
        width: 60%;
        z-index: 2;
        mix-blend-mode: color-dodge;
        display: block;

        @media (max-width: 768px) {
            padding-bottom: 50px;
        }

        @media (max-width: 480px) {
            svg {
                min-width: calc(100% + 200px);
                right: 50%;
                position: absolute;
                transform: translate(50%, -50%);
                top: 50%;
            }
        }
    }

    &__logo {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        mix-blend-mode: color-dodge;

        @media (max-width:1024px) {
            width: 100%;
        }
    }
}
