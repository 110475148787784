body {
    min-height: 100vh;
}

#gradient-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    --gradient-color-1: #76CFFE;
    --gradient-color-2: #4395C7;
    --gradient-color-3: #15C6FF;
    --gradient-color-4: #064986;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 182px), 0% 100%);

    @media (max-width: 1919px) {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - calc(91px + (182 - 91) * ((100vw - 320px) / (1919 - 320)))), 0% 100%);
    }
}

.st12 {
    fill: #F9FEFF;
}

.st16 {
    fill: #096DB4;
}