.work {
    &__section {
        padding-top: 61px;
        padding-bottom: 96px;
        background: rgb(246, 249, 252);
        display: block;
        position: relative;

        @media (max-width: 767px) {
            padding-top: 45px;
            padding-bottom: 86px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 270px;
            height: 36px;
            transform: translateY(50%);
            background: rgb(31, 177, 255);
            z-index: 2;

            @media (max-width: 1084px) {
                width: calc(126px + (270 - 126) * ((100vw - 320px) / (1084 - 320)));
                height: calc(10px + (36 - 10) * ((100vw - 320px) / (1084 - 320)));
            }
        }
    }

    &__container {
        margin: 0 auto;
        position: relative;
    }

    &__title-block {
        padding-bottom: 24px;
    }

    &__title {
        color: rgb(0, 0, 0);
        font-size: 56px;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: left;

        @media (max-width: 1084px) {
            font-size: calc(34px + (56 - 34) * ((100vw - 320px) / (1084 - 320)));
            line-height: calc(39px + (64 - 39) * ((100vw - 320px) / (1084 - 320)));
        }
    }

    &__text {
        color: rgb(66, 84, 102);
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 1px;
        text-align: left;
        max-width: 663px;
        padding-bottom: 69px;

        @media (max-width: 1084px) {
            line-height: calc(20px + (28 - 20) * ((100vw - 320px) / (1084 - 320)));
        }

        @media (max-width: 767px) {
            padding-bottom: 22px;
        }
    }

    &__slider {
        &-arrow-block {
            position: absolute;
            top: 50%;
            right: 73px;
            display: flex;
            align-items: center;
            gap: 13px;

            @media (max-width: 1023px) {
                display: none;
            }

        }

        &-arrow {
            cursor: pointer;
            width: 28px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #064986;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            &-next {
                transform: rotate(180deg);
            }
        }

        &-block {
            max-width: 100%;
            overflow: hidden;
        }

        &-wrapper {
            width: calc(125%);
            visibility: visible;
            transform: translateX(-10%);
        }

        &-item {
            display: flex;
            flex-direction: column;
            padding-top: 98px;
            border-top: 12px solid #064986;
            border-radius: 8px;
            min-height: 325px;
            padding-left: 34px;
            padding-right: 34px;
            padding-bottom: 20px;
            background-color: #fff;

            @media (max-width: 767px) {
                padding-left: 47px;
                padding-top: 86px;
            }

            &-title {
                position: relative;
                color: rgb(0, 0, 0);
                font-family: Arial;
                text-align: left;
                margin-bottom: 25px;

                h3 {
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0px;
                    word-wrap: break-word;

                    @media (max-width: 1084px) {
                        font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1084 - 320)));
                        line-height: calc(28px + (32 - 28) * ((100vw - 320px) / (1084 - 320)));
                    }
                }


                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: -10px;
                    width: 1px;
                    height: 100%;
                    background-color: rgb(6, 73, 134);
                }
            }

            &-text {
                color: rgb(66, 84, 102);
                font-family: Arial;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;

                @media (max-width: 1084px) {
                    line-height: calc(24px + (28 - 24) * ((100vw - 320px) / (1084 - 320)));
                }
            }
        }
    }
}