.tehnology {
    &__section {
        display: block;
        padding-bottom: 98px;

        @media (max-width:767px) {
            padding-bottom: 32px;
        }
    }

    &__container {
        position: relative;
        margin: 0 auto;
    }

    &__title-block {
        position: absolute;
        bottom: calc(100% - 38px);
        z-index: 3;
    }

    &__title {
        background: linear-gradient(-1.56deg, rgb(0, 0, 0), rgb(0, 77, 122));
        -webkit-background-clip:
            text;
        -webkit-text-fill-color:
            transparent;
        background-clip:
            text;
        text-fill-color:
            transparent;
        -webkit-text-fill-color: transparent;
        font-family: Arial;
        font-weight: 400;
        line-height: 98px;
        text-align: left;
        letter-spacing: -3px;
        text-stroke: 1px black;
        -webkit-text-stroke: 1px black;
        font-size: 64px;

        line-height: 1.5;

        @media (max-width: 1084px) {
            letter-spacing: calc(0px + (-3 - 0) * ((100vw - 320px) / (1084 - 320)));
            font-size: calc(24px + (64 - 24) * ((100vw - 320px) / (1084 - 320)));
        }
    }

    &__text {
        padding-top: 71px;
        max-width: 690px;
        color: rgb(66, 84, 102);
        font-family: Arial;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        letter-spacing: 0.5px;
        padding-bottom: 91px;

        @media (max-width: 480px) {
            color: rgb(108, 109, 109);
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left;
            padding-bottom: 34px;
        }
    }

    &__list-loop {
        display: flex;
    }

    &__list {
        display: flex;
        transition-timing-function: linear;
    }

    &__item {
        flex: 0 0 auto;
        padding: 0;
        width: 500px;
        user-select: none;
        will-change: transform;
        display: flex;
        justify-content: space-between;

        img {
            height: 48px;
        }
    }
}