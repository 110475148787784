.section {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.container {
    max-width: 1082px;
    width: 100%;
    padding: 0 20px;
}

* {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
}

ul {
    list-style: none;
}

.title {
    font-family: Arial;
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0%;
    text-align: left;

    @media (max-width: 1084px) {
        font-size: calc(34px + (56 - 34) * ((100vw - 320px) / (1084 - 320)));
    }
}

.g-recaptcha {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-right: 20px;

    @media (max-width:480px) {
        padding-right: 0;

        iframe {
            max-width: 100%;
            width: 100%;
        }
    }

    @media (max-width:400px) {
        &>div {
            max-width: 100%;
            width: 100% !important;
        }
    }

}