.benefits {
    &__section {
        background-color: #fff;
        padding-bottom: 73px;
        position: relative;

        @media (max-width: 767px) {
            padding-top: 50px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 270px;
            height: 36px;
            transform: translateY(50%);
            background: rgb(31, 177, 255);
            z-index: 2;

            @media (max-width: 1084px) {
                width: calc(153px + (270 - 153) * ((100vw - 320px) / (1084 - 320)));
                height: calc(10px + (36 - 10) * ((100vw - 320px) / (1084 - 320)));
            }
        }
    }

    &__title {
        margin-bottom: 55px;

        @media (max-width: 767px) {
            margin-bottom: 32px;
        }

        h2 {
            color: rgb(0, 0, 0);
            font-family: Arial;
            font-size: 56px;
            font-weight: 400;
            line-height: 64px;
            letter-spacing: 0%;
            text-align: left;

            @media (max-width: 1084px) {
                font-size: calc(34px + (56 - 34) * ((100vw - 320px) / (1084 - 320)));
            }
        }
    }

    &__block {
        display: grid;
        grid-template-columns: minmax(350px, 691px) minmax(320px, 335px);
        gap: 18px;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 18px;

        &-item {
            min-height: 330px;
            display: flex;
            flex-direction: column;
            padding: 28px 90px 28px 45px;
            gap: 16px;
            font-family: Arial;
            border: 0.2px solid rgb(14, 136, 211);
            border-radius: 8px;
            background: rgb(255, 255, 255);

            @media (max-width: 1084px) {
                padding: 28px 45px 28px 45px;
            }

            @media (max-width: 480px) {
                padding: 42px 20px 42px 20px;
            }


            &-count {
                color: rgb(6, 73, 134);
                font-size: 90px;
                font-weight: 400;
                line-height: 103px;
                letter-spacing: 0%;
                text-align: left;
            }

            &-title {
                color: rgb(0, 0, 0);
                font-size: 28px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0%;
                text-align: left;
            }

            &-text {
                color: rgb(108, 109, 109);
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0%;
                text-align: left;
            }
        }
    }

    &__counter {
        position: relative;

        &-wrapper {
            position: sticky;
            top: 10px;
            display: flex;
            flex-direction: column;
            gap: 29px;
        }

        &-block {
            display: block;
            padding: 15px 52px 15px 52px;
            border: 0.2px solid rgb(14, 136, 211);
            border-radius: 8px;
            background: rgb(255, 255, 255);

            @media (max-width: 1084px) {
                padding: 22px 45px 22px 45px;
            }

            @media (max-width: 480px) {
                padding: 22px 30px 22px 30px;
            }

            h3 {
                background: linear-gradient(179.02deg, #064986, rgb(255, 255, 255));
                -webkit-background-clip:
                    text;
                -webkit-text-fill-color:
                    transparent;
                background-clip:
                    text;
                text-fill-color:
                    transparent;
                font-size: 128px;
                font-weight: 700;
                line-height: 1;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1084px) {
                    text-align: center;
                }
            }
        }

        &-info {
            color: rgb(108, 109, 109);
            font-size: 14.8px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0%;
            text-align: left;
        }
    }
}